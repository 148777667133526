<template>
  <div>
    <img class="example-headImg" src="../assets/image/aboutus/06-0banner.png" />
    <div class="head-explain">
      <div class="head-explain-title">比孚信息科技</div>
      <div class="head-explain-content">比肩客户同行，深孚客户之望。</div>
      <div class="head-explain-content" style="margin-top: -3rem">
        成为客户首选的科技创新伙伴。
      </div>
    </div>
    <div class="head-list">
      <div
        class="head-list-item head-list-item-ischeck"
        style="margin-left: 26.3rem"
        @click="goAssignBlock('block' + 0, 50)">
        <span class="title">公司简介</span>
      </div>
      <div class="head-list-item" @click="goAssignBlock('block' + 1, 71.2)">
        <span class="title">关联公司介绍</span>
      </div>
      <div class="head-list-item" @click="goAssignBlock('block' + 2, 50)">
        <span class="title">资质荣誉</span>
      </div>
      <div class="head-list-item" @click="goAssignBlock('block' + 3, 50)">
        <span class="title">愿景使命</span>
      </div>
      <div class="head-list-item" @click="goAssignBlock('block' + 4, 50)">
        <span class="title">客户摘选</span>
      </div>
      <div class="head-list-item" @click="goAssignBlock('block' + 5, 50)">
        <span class="title">合作伙伴</span>
      </div>
    </div>
    <div class="container-1" style="display: flex" ref="block0">
      <div class="aboutus-box">
        <div class="left">
          <img src="../assets/image/aboutus/06-0小图.png" />
        </div>
        <div class="right">
          <div class="title1">公司简介</div>
          <div class="title2">
            上海比孚信息科技有限公司（以下简称“比孚”）是一家成立于2013年的专注于IT技术服务的高新技术企业。<br /><br />
            比孚核心团队成员来自多家国际知名咨询和信息技术服务公司，在toB信息化咨询领域有着丰富的管理和落地经验。<br /><br />
            随着数字化转型的深入以及IT技术的快速发展，企业的IT投入逐年升高，IT的ROI显得更加重要。面对不同行业不同业务的业务和技术需求，比孚凭借积累多年的方法论和工具，始终围绕着价值和效率，为客户提供专业，快速见效的解决方案。<br /><br />
            为向客户提供更具针对性的专业服务，比孚将技术服务整合为数据服务、SAP服务和技术开发服务三大服务板块，提供从咨询设计到开发落地运维的完整技术服务。业务上，比孚聚焦于快消零售，制造以及医疗三大行业，不断研发和打磨针对行业的解决方案和产品，帮助企业以更低成本更好效果更短时间内实现业务流程的数字化转型，提升业务效率，减少人工，优化成本。
          </div>
        </div>
      </div>
    </div>
    <!-- style="height: 68.2rem" -->
    <div class="container-2" style="height: 71.2rem" ref="block1">
      <div class="affiliated-title">关联公司介绍</div>
      <div class="affiliated-box">
        <div v-for="(item,index) in affiliatedLists" :key="index" class="card-affiliated" 
          :class="item.isHover?'card-affiliated-hover-'+item.type:'card-affiliated-no-hover'" 
           @mouseenter="handleMouseenter(item,index)"
           @mouseleave="handleMouseleave(item,index)"
           @click="handleJumpTo(item.type)"
          >
          <div class="affiliated-header">
            <img :class="item.type === 'asialink'?'header-img-asialink':'header-img'" 
            :src="item.isHover?item.imageHover:item.image"
            />
          </div>
          <div class="affiliated-content" :class="item.isHover?'affiliated-content-hover':'affiliated-content-no-hover'">{{ item.value }}</div>
          <div class="affiliated-footer" v-if="item.type !== 'yijia'">
            <div class="footer-button-radio">
              <img :src="item.isHover?item.imageHoverRadio:item.imageRadio" style="width:2.4rem; height:2.4rem;" alt="">
            </div>
            <div class="footer-button" :class="item.isHover?'footer-button-hover':'footer-button-'+item.type">
              <div class="footer-button-value">了解详情</div>
              <img class="footer-button-icon" :src="item.isHover?item.imageHoverArrow:item.imageArrow" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="container-1" ref="block2">
      <div class="container-title" style="padding-top: 6rem">
        资质荣誉
      </div>
      <el-carousel indicator-position="outside" height="46rem">
        <el-carousel-item v-for="item in box4List" :key="item.index">
          <div class="aboutus-container">
            <img v-if="item.url1" :src="item.url1" />
            <img v-if="item.url2" :src="item.url2" />
            <img v-if="item.url3" :src="item.url3" />
            <img v-if="item.url4" :src="item.url4" />
            <img v-if="item.url5" :src="item.url5" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container-2" style="height: 50rem" ref="block3">
      <div
        class="abouts-title"
        style="margin: 0 0 0 26.2rem; padding-top: 6rem">
        愿景使命
      </div>
      <div class="aboutus-box2">
        <div class="card">
          <img src="../assets/image/aboutus/06-0使命.svg" />
          <div class="title1">使命</div>
          <div class="title2">
            推动企业数字化重塑和创新，<br />助力企业成为数字化转型和创新的受益者
          </div>
        </div>
        <div class="card">
          <img src="../assets/image/aboutus/06-0愿景.svg" />
          <div class="title1">愿景</div>
          <div class="title2">
            比肩客户同行，深孚客户之望。 <br />成为客户首选的科技创新伙伴。
          </div>
        </div>
        <div class="card">
          <img src="../assets/image/aboutus/06-0价值观.svg" />
          <div class="title1">价值观</div>
          <div class="title2">诚信 担当<br />创新 共赢</div>
        </div>
      </div>
    </div>

    <div class="container-1" style="margin-bottom: 10rem" ref="block4">
      <div class="abouts-title" style="margin: 6rem 0 0 26.2rem" >
        客户摘选
      </div>
      <img
        class="aboutus-img1"
        src="../assets/image/aboutus/06-0客户摘选.png" />
      <div class="abouts-title" style="margin: 6rem 0 0 26.2rem" ref="block5">
        合作伙伴
      </div>
      <img
        class="aboutus-img2"
        src="../assets/image/aboutus/06-0合作伙伴.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BizfocusPagesPurchaseCollaboration",
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content:
          "关于我们,关于比孚,比孚,上海比孚,比孚信息,比孚信息科技,数字化转型,数据湖仓技术,SAP实施运维,定制开发运维,亚马逊合作伙伴,SAP全球认证,快消零售,医疗健康,制造行业,企业数字化解决方案,饮料供应链,供应链管理平台,采购协同,数字化生产,智能仓储,高级排程,数字化工单,安全观察,知识与体系管理,智慧供应链解决方案专家,工业4.0智慧工厂,医疗行业解决方案,医药行业一站式解决方案,销售效能管理,销售运营管理,学术会议管理,市场准入与商务运营,制造行业,人事管理,质量体系管理,投资项目管理,可口可乐数字化产线,可口可乐智能仓储,可口可乐赋码系统,可口可乐SAP运维,数据湖仓一体化,数据湖迁移,市场渗透率分析,商情诊断,整合数据",
      },
      {
        name: "description",
        content:
          "上海比孚信息科技有限公司（以下简称“比孚”）是一家成立于2013年的专注于IT技术服务的高新技术企业。比孚核心团队成员来自多家国际知名咨询和信息技术服务公司，在toB信息化咨询领域有着丰富的管理和落地经验。随着数字化转型的深入以及IT技术的快速发展，企业的IT投入逐年升高，IT的ROI显得更加重要。面对不同行业不同业务的业务和技术需求，比孚凭借积累多年的方法论和工具，始终围绕着价值和效率，为客户提供专业，快速见效的解决方案。为向客户提供更具针对性的专业服务，比孚将技术服务整合为数据服务、SAP服务和技术开发服务三大服务板块，提供从咨询设计到开发落地运维的完整技术服务。业务上，比孚聚焦于快消零售，制造以及医疗三大行业，不断研发和打磨针对行业的解决方案和产品，帮助企业以更低成本更好效果更短时间内实现业务流程的数字化转型，提升业务效率，减少人工，优化成本。",
      },
    ],
  },
  data() {
    return {
      box4List: [
        {
          url1: require("../assets/image/aboutus/06-0资质1-1.png"),
          url2: require("../assets/image/aboutus/06-0资质1-2.png"),
          // url3: require("../assets/image/aboutus/06-0资质1-3.png"),
          url4: require("../assets/image/aboutus/06-0资质2-1.png"),
          url5: require("../assets/image/aboutus/06-0资质2-2.png"),
        },
        {
          url1: require("../assets/image/aboutus/06-0资质3.png"),
          url2: require("../assets/image/aboutus/06-0资质4.png"),
          // url3:require("../assets/image/aboutus/06-0资质5.png"),
          // url4:require("../assets/image/aboutus/06-0资质6.png"),
          // url5:require("../assets/image/aboutus/06-0资质7.png")
        },
        // {
        // url1:require("../assets/image/aboutus/06-0资质8.png"),
        // url2:require("../assets/image/aboutus/06-0资质9.png"),
        // url3:require("../assets/image/aboutus/06-0资质10.png"),

        // }
      ],
      navOffsetTop: 0,
      affiliatedLists: [
        {
          image: require('@/assets/image/index/affiliated/huajian@2x.png'),
          imageHover:require('@/assets/image/index/affiliated/hover-huajian@2x.png'),
          imageRadio: require('@/assets/image/index/affiliated/radio-huajian@2x.png'),
          imageHoverRadio: require('@/assets/image/index/affiliated/hover-radio@2x.png'),
          imageArrow: require('@/assets/image/index/affiliated/arrow-huajian@2x.png'),
          imageHoverArrow:require('@/assets/image/index/affiliated/hover-arrow@2x.png'),
          type: 'huajian',
          value: '上海画简信息科技，作为上海比孚的孵化企业，凭借 VTicket 和 VTeam 两款明星产品。在IT服务管理和项目管理领域展现出卓越的应用能力和显著优势。这两款产品以其高效、便捷和智能的特点，为企业提供了一流的服务管理解决方案。',
          isShowIcon: true,
          isHover:false,
        },
        {
          image: require('@/assets/image/index/affiliated/asialink@2x.png'),
          imageHover: require('@/assets/image/index/affiliated/hover-asialink@2x.png'),
          imageRadio: require('@/assets/image/index/affiliated/radio-asialink@2x.png'),
          imageHoverRadio: require('@/assets/image/index/affiliated/hover-radio@2x.png'),
          imageArrow: require('@/assets/image/index/affiliated/arrow-asialink@2x.png'),
          imageHoverArrow:require('@/assets/image/index/affiliated/hover-arrow@2x.png'),
          type: 'asialink',
          value: '作为SAP全球认证的合作伙伴，艾思林柯SAP的战略合作覆盖了零售，饮料，食品，制造等主要行业。艾思林柯凭借着全方位的服务、丰富的行业经验、对信息技术趋势的洞察和扎实的服务能力。共同协助客户进行数字化转型、云部署和数字化创新，并为客户提供一个可持续增长的数字化新未来。',
          isShowIcon: true,
          isHover:false,
        },
        {
          image: require('@/assets/image/index/affiliated/yijia@2x.png'),
          imageHover: require('@/assets/image/index/affiliated/hover-yijia@2x.png'),
          imageRadio: require('@/assets/image/index/affiliated/radio-yijia@2x.png'),
          imageHoverRadio: require('@/assets/image/index/affiliated/hover-radio@2x.png'),
          imageArrow: require('@/assets/image/index/affiliated/arrow-yijia@2x.png'),
          imageHoverArrow:require('@/assets/image/index/affiliated/hover-arrow@2x.png'),
          type: 'yijia',
          value: '上海医嘉数字科技公司专注于提供企业综合IT技术服务，拥有丰富的信息化咨询领域管理和实践经验。在为客户服务的过程中，医嘉不仅追求技术交付的高品质，更持续监控和管理潜在风险，并制定完备的应急预案，以保障服务的卓越性和长期稳定性。',
          isShowIcon: true,
          isHover:false,
        }
      ],
    };
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.fiexdNav);
    this.getData();
  },
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.fiexdNav);
  },
  methods: {
    goAssignBlock(el, speed) {
      const navHeight = document.querySelector(".head-list").offsetHeight;
      let _this = this;
      let windowH = window.innerHeight; //浏览器窗口高度
      let h = this.$refs[el].offsetHeight; //模块内容高度
      let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
      let top = t - 30; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop - navHeight);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed - navHeight);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    },
    /** 设置导航条nav到达页面顶部时固定 **/
    // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
    getData() {
      this.navOffsetTop = document.querySelector(".head-list").offsetTop + 60;
      console.log("this.navOffsetTop", this.navOffsetTop);
    },
    fiexdNav() {
      // 2.获取当前页面的卷曲高度
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const nav = document.querySelector(".head-list");
      // 3.判断卷曲高度是否大于等于导航条的offsetTop值
      if (scrollTop > this.navOffsetTop) {
        //   3.1若满足，则给nav导航添加固定样式
        nav.classList.add("fixedNav");
      } else {
        //   3.2若不满足，则删除nav导航的固定样式
        nav.classList.remove("fixedNav");
      }

      /** 当滚动到一定区域时给导航项添加选中样式 **/
      //  1.获取所有锚点元素
      // 2.获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = [];
      // contents.forEach(item => {
      //     contentsOffsetTop.push(item.offsetTop)
      //     console.log('2222',item.offsetTop)
      // })
      contentsOffsetTop.push(this.$refs["block0"].offsetTop);
      contentsOffsetTop.push(this.$refs["block1"].offsetTop);
      contentsOffsetTop.push(this.$refs["block2"].offsetTop);
      contentsOffsetTop.push(this.$refs["block3"].offsetTop);
      contentsOffsetTop.push(this.$refs["block4"].offsetTop);
      // 3.获取页面高度
      const pageHeight = window.innerHeight;
      // 4.获取nav的子元素
      const navChildren = document.querySelectorAll(
        ".head-list .head-list-item"
      );
      // 5.遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        // 5.1 设置第一项导航默认为选中状态
        if (i === 0) {
          navChildren[0].classList.add("head-list-item-ischeck");
        } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 5) {
          // 排他思想
          for (let j = 0; j < contentsOffsetTop.length; j++) {
            navChildren[j].classList.remove("head-list-item-ischeck");
            navChildren[i].classList.add("head-list-item-ischeck");
          }
        } else {
          navChildren[i].classList.remove("head-list-item-ischeck");
        }
      }
    },
    /**
     *设置点击导航跳转到指定选择器对应的锚点元素
     * @param {*} selector
     **/
    skipTo(selector) {
      const navHeight = document.querySelector(".head-list-item").offsetHeight;
      // scrollIntoView() js原生方法，实现锚点滚动过渡
      const target = document.querySelector(selector);
      target.scrollIntoView({ behavior: "smooth" });
      // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
      window.scrollTo(0, target.offsetTop - navHeight);
    },

    handleMouseenter(item, index) {
     console.log('mouseenter - item',item) 
      console.log('mouseenter - index', index) 
     this.affiliatedLists[index].isHover = true
    },

    handleMouseleave(item, index) {
      this.affiliatedLists[index].isHover = false
    },

    // 页面跳转
    handleJumpTo(type) {
      if (type === 'huajian') {
        window.open('https://visualticket.cn/', '_blank');            
      } else if (type === 'asialink') {
        window.open('https://asialink.sh.cn/', '_blank');            
      } 
    }
  },
};
</script>

<style lang="less" scoped>
.aboutus-box {
  width: 140rem;
  display: flex;
  margin: 5.6rem 0 3.6rem 26rem;
  .left {
    width: 69.9rem;
    height: 46.4rem;
    img {
      width: 69.9rem;
      height: 46.4rem;
    }
  }
  .right {
    width: 65.1rem;
    margin-left: 5rem;
    .title1 {
      width: 12rem;
      height: 3.6rem;
      font-size: 3rem;
      font-family: "CN_Medium";
      font-weight: 500;
      color: #333333;
      line-height: 3.6rem;
    }
    .title2 {
      width: 65.3rem;
      height: 44.3rem;
      font-size: 1.6rem;
      font-family: "CN_Regular";
      font-weight: 400;
      color: #333333;
      line-height: 2.8rem;
      text-align: justify;
      margin-top: 1.7rem;
    }
  }
}
.aboutus-box2 {
  width: 140rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10rem 0 0 26.6rem;
  .card {
    width: 45.6rem;
    height: 26.7rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(233, 233, 233, 0.6);
  }
  img {
    width: 6.4rem;
    height: 6.4rem;
    margin-top: 4rem;
  }
  .title1 {
    // height: 2rem;
    font-size: 2rem;
    font-family: "CN_Medium";
    font-weight: 500;
    color: #000000;
    line-height: 3rem;

    margin-top: 3rem;
  }
  .title2 {
    height: 7.5rem;
    font-size: 1.6rem;
    font-family: "CN_Regular";
    font-weight: 400;
    color: #4f4f4f;
    line-height: 2.6rem;
    // width: 65.1rem;
    margin-top: 3rem;
  }
}
.abouts-title {
  width: 14.4rem;
  height: 3.6rem;
  font-size: 3.6rem;
  font-family: "CN_Medium";
  font-weight: 500;
  color: #333333;
  line-height: 5.4rem;
}
.abouts-title-relevance {
  width: 26rem;
  height: 3.6rem;
  font-size: 3.6rem;
  font-family: "CN_Medium";
  font-weight: 500;
  color: #333333;
  line-height: 5.4rem;
}
.card-relevance{
  width: 45rem;
  height: 49rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(200,198,207,0.5);
  border-radius: 20px;
}
.aboutus-container {
  width: 140rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 5rem 0 0 26.4rem;
  img {
    width: 26rem;
    height: 35rem;
    margin-bottom: 2rem;
    margin-right: 12rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.aboutus-img1 {
  width: 141.8rem;
  height: 41.6rem;
  margin-top: 2.8rem;
}
.aboutus-img2 {
  width: 142rem;
  height: 13rem;
  margin-top: 2.5rem;
}

.purchase-content-left {
  width: 33rem;
  text-align: justify;
  margin-left: 26rem;
  margin-top: 5.9rem;
}
.purchase-title {
  height: 2rem;
  font-size: 2rem;
  font-family: "CN_Medium";
  font-weight: 500;
  color: #000000;
  line-height: 3rem;
}
.purchase-content {
  height: 7.7rem;
  font-size: 1.6rem;
  font-family: "CN_Normal";
  font-weight: 400;
  color: #666666;
  line-height: 2.6rem;

  margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right {
  text-align: justify;
  margin-top: 5.9rem;
  padding-bottom: 2rem;
}
.solution-img {
  width: 107.2rem;
  height: 69.1rem;
}
.benefits-img {
  width: 106.3rem;
  height: 66.2rem;
}
.solution-special {
  margin: 0 0 0 35.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
  font-family: "CN_Normal";
  font-weight: 400;
  color: #11a84f;
  line-height: 2.4rem;
}
.solution-special-img {
  width: 89.1rem;
  height: 1.1rem;
  margin: 1.2rem 0 6.1rem 6.4rem;
}

.affiliated-title {
  margin: 0px 0px 0px 26.2rem;
  padding-top: 6rem;
  width: 26rem;
  // height: 3.6rem;
  font-size: 3.6rem;
  font-family: "CN_Medium";
  font-weight: 500;
  color: #333333;
  line-height: 5.4rem;
}
.affiliated-box{
  width: 140rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5rem 0 0 26.6rem;
  cursor: pointer;

  .card-affiliated{
    width: 45rem;
    height: 49rem;
    border-radius: 20px;
    .affiliated-header{
      height: 40%;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中，如果需要的话 */
      .header-img{
        width: 31.7rem;
      }
      .header-img-asialink{
        width: 25.3rem;
      }
    }
    .affiliated-content{
      height: 38%;
      text-align: left;
      font-size: 1.6rem;
      font-family: CN_Regular;
      font-weight: 400;
      line-height: 2.6rem;
      padding: 0 3.3rem;
    }
    .affiliated-content-no-hover{
      color: #4f4f4f;
    }
    .affiliated-content-hover{
      color: #fff;
    }
    .affiliated-footer{
      height: 22%;
      padding-top: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .footer-button-radio{
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        position: relative;
        top: -1rem;
        left: 1.2rem;
      }
      .footer-button{
        width: 12.8rem;
        height: 3.6rem;
        padding: 0 1.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-button-value{
          font-size: 1.6rem;
        }
        .footer-button-icon{
          width: 1.7rem;
          height: 1.7rem;
        }
      }
      .footer-button-hover{
        border:1px solid #FFF;
        color: #FFF;
      }
      .footer-button-huajian{
        border:1px solid #6633FF;
        color: #6633FF;
      }
      .footer-button-asialink{
        border:1px solid #33318F;
        color: #33318F;
      }
      .footer-button-yijia{
        border:1px solid #2163E8;
        color: #2163E8;
      }
      .imageRadio{
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .card-affiliated-no-hover{
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(200,198,207,0.5);
  }
  .card-affiliated-hover-huajian{
    transform: scale(1.05) translateY(2.5%);
    background: linear-gradient( 135deg, #B585FF 0%, #4D1DD8 100%);
    box-shadow: 0px 4px 18px 0px rgba(1,0,53,0.5);
  }
  .card-affiliated-hover-asialink{
    transform: scale(1.05) translateY(2.5%);
    background: linear-gradient( 135deg, #7976C8 0%, #211F80 100%);
    box-shadow: 0px 4px 18px 0px rgba(1,0,53,0.5);
  }
  .card-affiliated-hover-yijia{
    transform: scale(1.05) translateY(2.5%);
    background: linear-gradient( 135deg, #7FDD99 0%, #2163E8 100%);
    box-shadow: 0px 4px 18px 0px rgba(1,0,53,0.5);
  }

}

</style>
